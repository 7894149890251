import { useHead, useRuntimeConfig } from "#imports";
import { withQuery } from "ufo";
import { disableAnalytics as _disableAnalytics, enableAnalytics as _enableAnalytics } from "../analytics.js";
import { gtag, initGtag, resolveTags } from "../utils.js";
export function useGtag() {
  const options = useRuntimeConfig().public.gtag;
  const rawTags = resolveTags(options);
  let _gtag;
  if (import.meta.server)
    _gtag = () => {
    };
  else if (import.meta.client)
    _gtag = gtag;
  const getTag = (id) => {
    const tags = [...rawTags];
    let tag = tags.find((tag2) => tag2.id === id);
    if (!tag) {
      if (id) {
        tag = { id };
        tags.unshift(tag);
      } else {
        tag = tags[0];
      }
    }
    if (!tag)
      console.error("[nuxt-gtag] Missing Google tag ID");
    return { tag, tags };
  };
  const initialize = (id) => {
    if (import.meta.client) {
      const { tag, tags } = getTag(id);
      if (!tag)
        return;
      if (!window.dataLayer)
        initGtag({ tags });
      if (!document.head.querySelector("script[data-gtag]")) {
        useHead({
          script: [{
            "src": withQuery(options.url, { id: tag.id }),
            "data-gtag": ""
          }]
        });
      }
    }
  };
  function disableAnalytics(id) {
    if (import.meta.client) {
      const { tag } = getTag(id);
      if (tag)
        _disableAnalytics(tag.id);
    }
  }
  function enableAnalytics(id) {
    if (import.meta.client) {
      const { tag } = getTag(id);
      if (tag)
        _enableAnalytics(tag.id);
    }
  }
  return {
    gtag: _gtag,
    initialize,
    disableAnalytics,
    enableAnalytics
  };
}
