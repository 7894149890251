export default defineNuxtPlugin({
  name: 'kygunco:meta',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const config = useRuntimeConfig().public.meta;

    const { $bus } = useNuxtApp();

    const enabled = config.enabled && !!config.pixelId?.length;

    const { status, load } = useScriptMetaPixel({
      id: config.pixelId,
      scriptOptions: {
        trigger: 'manual',
        mode: 'client',
      },
    });

    const ready = computed(() => status.value == 'loaded');

    $bus.once('app:interaction', () => !enabled || load());

    return {
      provide: {
        meta: {
          enabled,
          ready,
        },
      },
    };
  },
});
