import { whenever } from '@vueuse/core';

interface ProfileProperties {
  email?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  title?: string;
  organization?: string;
}

interface AddedCartItem {
  name: string;
  id: string;
  sku: string;
  categories: string[];
  imageUrl?: string;
  url: string;
  price: number;
  quantity: number;
}

type IdentifyParams = ProfileProperties & Record<string, unknown>;

interface KlaviyoApi {
  identify: (params: IdentifyParams, callback?: () => void) => Promise<void>;
  track: (
    event: string,
    data: Record<string, unknown>,
    callback?: (result: boolean) => void) => Promise<boolean>;
  trackViewedItem: (item: Record<string, unknown>, callback?: () => void) => Promise<void>;
  isIdentified: (callback?: (result: boolean) => void) => Promise<boolean>;
  openForm: (formId: string, callback?: (result: string) => void) => Promise<string>;
  push: (...args: unknown[]) => void;
}

declare global {
  interface Window {
    _klOnsite: unknown[];
    klaviyo: KlaviyoApi;
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:klaviyo',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const config = useRuntimeConfig().public.klaviyo;

    const enabled = config.enabled && !!config.publicKey;

    const userStore = useUserStore();

    const { $bus } = useNuxtApp();

    const { status, load, proxy } = useScript(
      {
        src: `https://static.klaviyo.com/onsite/js/${config.publicKey}/klaviyo.js`,
        referrerpolicy: false,
        crossorigin: false,
      },
      {
        trigger: 'manual',
        mode: 'client',
        beforeInit: () => {
          if (import.meta.server) {
            return;
          }

          window._klOnsite = window._klOnsite || [];

          window.klaviyo = {
            push: (...args) => window._klOnsite.push(args),
            identify: (params, callback) => new Promise((resolve) => {
              window._klOnsite.push(['identify', params, () => {
                callback?.();
                resolve();
              }]);
            }),
            isIdentified: callback => new Promise((resolve) => {
              window._klOnsite.push(['isIdentified', (result: boolean) => {
                callback?.(result);
                resolve(result);
              }]);
            }),
            track: (event, data, callback) => new Promise((resolve) => {
              window._klOnsite.push(['track', event, data, (result: boolean) => {
                callback?.(result);
                resolve(result);
              }]);
            }),
            trackViewedItem: (item, callback) => new Promise((resolve) => {
              window._klOnsite.push(['trackViewedItem', item, () => {
                callback?.();
                resolve();
              }]);
            }),
            openForm: (formId, callback) => new Promise((resolve) => {
              window._klOnsite.push(['openForm', formId, (result: string) => {
                callback?.(result);
                resolve(result);
              }]);
            }),
          };
        },
        use: () => window.klaviyo,
      });

    const ready = computed(() => status.value == 'loaded');

    whenever(() => userStore.data, user => proxy.identify({
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      phone_number: user.phoneNumber,
    }), { immediate: true });

    const openForm = (formId?: string) => {
      if (!config.enabled) {
        return;
      }

      proxy.openForm(formId ?? config.formId);
    };

    $bus.once('app:interaction', () => !enabled || load());

    $bus.on('product:view', (product, variation) => proxy.track('Viewed Product', {
      ProductName: variation?.name ?? product.name,
      ProductID: product.id,
      SKU: product.style,
      Categories: [],
      ImageURL: product.thumbnailUrl,
      URL: withSiteUrl(`/product/${product.slug}`).value,
      Brand: product.manufacturer?.name,
      Price: variation?.price ?? product.price,
    }));

    $bus.on('product:add', (product, variation, quantity) => handleAddedToCart({
      id: product.id,
      sku: product.style,
      name: variation?.name ?? product.name,
      categories: [],
      imageUrl: product.thumbnailUrl ?? undefined,
      url: withSiteUrl(`/product/${product.slug}`).value,
      price: product.price,
      quantity: quantity ?? 1,
    }));

    $bus.on('catalog:add', product => handleAddedToCart({
      id: product.id,
      sku: product.style,
      name: product.name,
      categories: [],
      imageUrl: product.thumbnailUrl ?? undefined,
      url: withSiteUrl(`/product/${product.slug}`).value,
      price: product.price,
      quantity: 1,
    }));

    const handleAddedToCart = (item: AddedCartItem) => {
      const cartStore = useCartStore();

      const cart = cartStore.data!;

      proxy.track('Added to Cart', {
        $value: cart.subTotal,
        AddedItemProductName: item.name,
        AddedItemProductID: item.id,
        AddedItemSKU: item.sku,
        AddedItemCategories: [],
        AddedItemImageURL: item.imageUrl,
        AddedItemURL: item.url,
        AddedItemPrice: item.price,
        AddedItemQuantity: item.quantity,
        CheckoutURL: withSiteUrl('/checkout').value,
        ItemNames: cart.items.map(i => i.name),
        Items: cart.items.map(i => ({
          ProductID: i.productId,
          SKU: i.style,
          ProductName: i.name,
          Quantity: i.quantity,
          ItemPrice: i.price,
          RowTotal: i.total,
          ProductURL: withSiteUrl(`/product/${i.productId}`).value,
          ImageURL: i.thumbnailUrl,
          ProductCategories: [],
        })),
      });
    };

    $bus.on('checkout:start', () => {
      const cartStore = useCartStore();

      const cart = cartStore.data!;

      proxy.track('Started Checkout', {
        $event_id: `${cart.id}_${Date.now() / 1000}}`,
        $value: cart.subTotal,
        ItemNames: cart.items.map(i => i.name),
        CheckoutURL: withSiteUrl('/checkout').value,
        Categories: [],
        Items: cart.items.map(i => ({
          ProductID: i.productId,
          SKU: i.style,
          ProductName: i.name,
          Quantity: i.quantity,
          ItemPrice: i.price,
          RowTotal: i.total,
          ProductURL: withSiteUrl(`/product/${i.productId}`).value,
          ImageURL: i.thumbnailUrl,
          ProductCategories: [],
        })),
      });
    });

    return {
      provide: {
        klaviyo: {
          enabled,
          ready,
          track: proxy.track,
          openForm,
        },
      },
    };
  },
});
