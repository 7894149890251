import type { RouteLocation, RouteLocationRaw } from 'vue-router';
import { useNow, whenever } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { FetchError } from 'ofetch';
import type {
  AuthenticateRequest,
  AuthenticateResponse,
  ProblemDetails,
} from '~/types/ecommerce';

export const useAuthStore = defineStore('auth', () => {
  const accessToken = useCookie('kygunco_access_token', {
    secure: true,
    sameSite: 'lax',
    maxAge: MINUTE_IN_SECONDS * 30,
  });

  const refreshToken = useCookie('kygunco_refresh_token', {
    secure: true,
    sameSite: 'lax',
    maxAge: DAY_IN_SECONDS * 30,
  });

  const { $ecommerce, $bus } = useNuxtApp();

  const pending = ref(false);

  const now = useNow({ interval: 1000 });

  const login = async (request: AuthenticateRequest) => {
    const cartStore = useCartStore();

    request.cartId = cartStore.guestCartId;

    const response = await $ecommerce.fetch<AuthenticateResponse>(
      'auth/authenticate',
      {
        method: 'POST',
        body: request,
        onRequest: () => { pending.value = true; },
        onResponse: () => { pending.value = false; },
      },
    ).catch((error: FetchError<ProblemDetails>) => {
      const _ = error.status == 401
        ? Notify.create({
            type: 'negative',
            message:
          'That username/password combination was not found, please try again.',
          })
        : $ecommerce.handle(error);

      return null;
    });

    cartStore.guestCartId = response ? null : cartStore.guestCartId;
    accessToken.value = response?.accessToken;
    refreshToken.value = response?.refreshToken;
  };

  const logout = (redirect?: string | RouteLocation | RouteLocationRaw) => {
    accessToken.value = undefined;
    refreshToken.value = undefined;

    Notify.create({
      type: 'positive',
      message: 'You\'ve been successfully logged out. Hope to see you soon!',
    });

    if (redirect) {
      navigateTo(redirect);
    }
  };

  const refresh = async () => {
    const response = await $ecommerce.fetch<AuthenticateResponse>(
      'auth/refresh',
      {
        method: 'POST',
        body: { refreshToken: refreshToken.value },
        onRequest: () => { pending.value = true; },
        onResponse: () => { pending.value = false; },
      },
    ).catch((error: FetchError<ProblemDetails>) => {
      if (error.status == 401) {
        Notify.create({
          type: 'warning',
          message: `Your current session is expires in ${refreshTokenExpiresIn.value}.`,
        });

        refreshToken.value = undefined;
        return null;
      }

      $ecommerce.handle(error);
    });

    accessToken.value = response?.accessToken ?? accessToken.value;
  };

  const decodedAccessToken = useJwt(() => accessToken.value ?? '');

  const decodedRefreshToken = useJwt(() => refreshToken.value ?? '');

  const accessTokenExpiresAt = computed(
    () => new Date((decodedAccessToken.payload.value?.exp ?? 0) * 1000),
  );

  const accessTokenExpiresIn = computed(() =>
    Math.floor(
      (accessTokenExpiresAt.value.getTime() - now.value.getTime()) / 1000,
    ),
  );

  const accessTokenExpired = computed(() => accessTokenExpiresIn.value <= 0);

  const refreshTokenExpiresAt = computed(
    () => new Date((decodedRefreshToken.payload.value?.exp ?? 0) * 1000),
  );

  const refreshTokenExpiresIn = computed(() =>
    Math.floor(
      (refreshTokenExpiresAt.value.getTime() - now.value.getTime()) / 1000,
    ),
  );

  const refreshTokenExpired = computed(() => refreshTokenExpiresIn.value <= 0);

  const shouldRefreshToken = computed(
    () => accessTokenExpiresIn.value <= MINUTE_IN_SECONDS * 10,
  );

  const loggedIn = computed(
    () => !!accessToken.value && !accessTokenExpired.value,
  );

  const cartId = computed(
    () =>
      (decodedAccessToken.payload.value as Record<string, string> | null)
        ?.cart_id,
  );

  const stopRefreshWatcher = whenever(
    () => shouldRefreshToken.value && !refreshTokenExpired.value,
    async () => await refresh(),
    { immediate: true },
  );

  whenever(refreshTokenExpired, stopRefreshWatcher);

  watch(loggedIn, status => $bus.emit(`auth:${status ? 'login' : 'logout'}`));

  return {
    login,
    logout,
    refresh,
    loggedIn,
    cartId,
    pending,
    accessToken,
    accessTokenExpired,
    accessTokenExpiresAt,
    accessTokenExpiresIn,
    refreshToken,
    refreshTokenExpired,
    refreshTokenExpiresAt,
    refreshTokenExpiresIn,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
