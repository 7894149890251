import { useToggle } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { UserResponse } from '~/types/ecommerce';

export const useUserStore = defineStore('user', () => {
  const authStore = useAuthStore();

  const { notify } = useQuasar();

  const { $ecommerce } = useNuxtApp();

  const [visible, toggle] = useToggle();

  const { data, status, execute, refresh, clear } = useAsyncData(
    'user',
    () => $ecommerce.fetch<UserResponse>('users/me')
      .catch((error) => {
        $ecommerce.handle(error);
        return undefined;
      }),
    {
      immediate: authStore.loggedIn,
    },
  );

  const pending = computed(() => status.value == 'pending');

  watch(
    () => authStore.loggedIn,
    async () => {
      // Clear user data if logged out
      if (!authStore.loggedIn) {
        clear();
        visible.value = false;
        return;
      }

      // Fetch user data if missing
      if (!data.value) {
        await execute();
      }
    },
  );

  watch(data, (newValue, oldValue) => {
    if (newValue && !oldValue) {
      notify({
        type: 'positive',
        message: authStore.loggedIn
          ? `Hi there, ${newValue.firstName}! Welcome back to KYGUNCO.`
          : `Welcome, ${newValue.firstName}. Thank you for signing up for KYGUNCO!`,
      });
    }
  });

  return {
    data,
    refresh,
    pending,
    visible,
    toggle,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
