declare module '~/plugins/bus' {
  export interface Events {
    'newsletter:opt-in': (payload: { email: string; firstName?: string; lastName?: string }) => void;
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:marketing',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const { $bus } = useNuxtApp();

    addRouteMiddleware('kygunco:marketing', (to) => {
      if (to.name == 'index') {
        useTrackEvent('conversion', {
          send_to: 'AW-1021436885/aJUhCIKY4pkZENXHh-cD',
        });
      }
    }, { global: true });

    $bus.on('newsletter:opt-in', data =>
      $fetch('/api/newsletter/subscriptions', {
        method: 'POST',
        body: { ...data },
      }),
    );

    $bus.on('product:add', (product, variation, quantity) =>
      useTrackEvent('conversion', {
        send_to: 'AW-1021436885/Yj-xCJrD5sMZENXHh-cD',
        currency: 'USD',
        value: (variation?.price ?? product.price) * (quantity ?? 1),
      }),
    );

    $bus.on('catalog:add', product =>
      useTrackEvent('conversion', {
        send_to: 'AW-1021436885/Yj-xCJrD5sMZENXHh-cD',
        currency: 'USD',
        value: product.price,
      }),
    );

    $bus.on('checkout:start', items =>
      useTrackEvent('conversion', {
        send_to: 'AW-1021436885/IXvlCIz17MMZENXHh-cD',
        currency: 'USD',
        value: items.map(i => i.total).reduce((a, b) => a + b, 0),
      }),
    );

    $bus.on('checkout:complete', (orders) => {
      for (const order of orders) {
        useTrackEvent('purchase', {
          send_to: 'AW-1021436885/ngm8CICk_JQZENXHh-cD',
          currency: 'USD',
          value: order.grandTotal,
          transaction_id: order.id,
        });
      }
    });
  },
});
