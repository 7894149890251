
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about6Vgf2e_45ZWwa_45DDxcsm2LFgKBFkBxwt_su_XdCkSeybIMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as brandsWUyLc_papgGZHSzHQoi_45dvMSJqu6Lyd4ZPZTscmaw_450Meta } from "/opt/buildhome/repo/pages/brands.vue?macro=true";
import { default as searchVZHeG3_X8t_IjAUUzx95piBYJ_XkAO6zB8qw3F4DYRcMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as contactxgbCBTXU_45d0XJYTpbmfD1UGZKjSEtzA80xt962KkT7QMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as dealersVu9CeseEFjQKacauhRMANOw9Zuw11nh_5Un_FVdlovUMeta } from "/opt/buildhome/repo/pages/dealers.vue?macro=true";
import { default as reviewsemZ_45BVrTYCgUaua6qlWsI9G4_45MrN52l_A_SL4zByMRIMeta } from "/opt/buildhome/repo/pages/reviews.vue?macro=true";
import { default as bow_45techUFAT_458ybJ4z07bSEJqrVZW2Nyi7abKQBv1EMkcbEXDUMeta } from "/opt/buildhome/repo/pages/bow-tech.vue?macro=true";
import { default as indexBeHzAQikKrSJqk6imgHKGnYps5J2m1dpAlMAyv_MDnQMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as _91slug_93TVXfQgR7E_1etoF9MB3cfpp6TKNvvtjRnrJxWZr59DgMeta } from "/opt/buildhome/repo/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93AsMp9aAcNK5awVwBA8bFuT1UtGDjJBRG_45WM1LHy0ih4Meta } from "/opt/buildhome/repo/pages/page/[slug].vue?macro=true";
import { default as _91slug_93SCFDDwJ2T6z2pDpVwTQRL_UZjFHV54OTpohp2betFGMMeta } from "/opt/buildhome/repo/pages/brand/[slug].vue?macro=true";
import { default as _91slug_93y1wdjaUgxv4uRTEc5J_450hFlnouzjtQXJCwKOuKKkPuYMeta } from "/opt/buildhome/repo/pages/group/[slug].vue?macro=true";
import { default as index2XR5TS_4vXnu1uXo8rq4TWQn3qVCwQlN5_EAVCQfZUoMeta } from "/opt/buildhome/repo/pages/videos/index.vue?macro=true";
import { default as indexsSHEynImpmVJQBsgEWtpFbTIimvAlNbjHy0W37sieOIMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as _91slug_93te8CY2ASpqYdeJ4p68d_4MokddFcdvBjw0_GkuNXf68Meta } from "/opt/buildhome/repo/pages/videos/[slug].vue?macro=true";
import { default as indexvn8uJREEzhPVS50vQdfGdegfUqxSSsr3dwAC67VMrfMMeta } from "/opt/buildhome/repo/pages/checkout/index.vue?macro=true";
import { default as _91slug_93IRf1U6PNralG_a_oSRboImI4r7kL8i4UA5MnHeAfxRoMeta } from "/opt/buildhome/repo/pages/product/[slug].vue?macro=true";
import { default as successOv_cN9oG7ZTNUGbJ4VNfoyziCB0bVSQ9yqNpCBwhX3oMeta } from "/opt/buildhome/repo/pages/checkout/success.vue?macro=true";
import { default as addressesFeqRjQ5QhRayHCnMV98wgjrWRbcUcPgjLNitHf5YEJMMeta } from "/opt/buildhome/repo/pages/account/addresses.vue?macro=true";
import { default as documentsHuseUiafUOx9A1UrORwM_YkhikhWXX6cyFScx7Ycx1wMeta } from "/opt/buildhome/repo/pages/account/documents.vue?macro=true";
import { default as _91_46_46_46slug_93MncC08W7EZDdTNm53Jc96KVso1csgnIJx_45EP45taNHsMeta } from "/opt/buildhome/repo/pages/category/[...slug].vue?macro=true";
import { default as _91id_93b4lY61wj16a8K6kyDbqGLE5Uel1xKulx2JH_jP4_ZtcMeta } from "/opt/buildhome/repo/pages/account/orders/[id].vue?macro=true";
import { default as indexZbLlXqe_45wEtVUmy6VGSnLOfOi62mTrWOclwgnuDcPmoMeta } from "/opt/buildhome/repo/pages/account/orders/index.vue?macro=true";
import { default as notificationsX94bmcoQuxHnT_yLyOU1rvk_45ujcvbrxitGpviN3o0BAMeta } from "/opt/buildhome/repo/pages/account/notifications.vue?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/opt/buildhome/repo/pages/brands.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "dealers",
    path: "/dealers",
    component: () => import("/opt/buildhome/repo/pages/dealers.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/opt/buildhome/repo/pages/reviews.vue")
  },
  {
    name: "bow-tech",
    path: "/bow-tech",
    component: () => import("/opt/buildhome/repo/pages/bow-tech.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/opt/buildhome/repo/pages/page/[slug].vue")
  },
  {
    name: "brand-slug",
    path: "/brand/:slug()",
    meta: _91slug_93SCFDDwJ2T6z2pDpVwTQRL_UZjFHV54OTpohp2betFGMMeta || {},
    alias: ["/manufacturer/:slug","/b/:slug"],
    component: () => import("/opt/buildhome/repo/pages/brand/[slug].vue")
  },
  {
    name: "group-slug",
    path: "/group/:slug()",
    component: () => import("/opt/buildhome/repo/pages/group/[slug].vue")
  },
  {
    name: "videos",
    path: "/videos",
    component: () => import("/opt/buildhome/repo/pages/videos/index.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexsSHEynImpmVJQBsgEWtpFbTIimvAlNbjHy0W37sieOIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "videos-slug",
    path: "/video/:slug",
    meta: _91slug_93te8CY2ASpqYdeJ4p68d_4MokddFcdvBjw0_GkuNXf68Meta || {},
    alias: ["/v/:slug","/videos/:slug"],
    component: () => import("/opt/buildhome/repo/pages/videos/[slug].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexvn8uJREEzhPVS50vQdfGdegfUqxSSsr3dwAC67VMrfMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/index.vue")
  },
  {
    name: "product-slug",
    path: "/product/:slug()",
    meta: _91slug_93IRf1U6PNralG_a_oSRboImI4r7kL8i4UA5MnHeAfxRoMeta || {},
    alias: ["/p/:slug"],
    component: () => import("/opt/buildhome/repo/pages/product/[slug].vue")
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    meta: successOv_cN9oG7ZTNUGbJ4VNfoyziCB0bVSQ9yqNpCBwhX3oMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/success.vue")
  },
  {
    name: "account-addresses",
    path: "/account/addresses",
    meta: addressesFeqRjQ5QhRayHCnMV98wgjrWRbcUcPgjLNitHf5YEJMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/addresses.vue")
  },
  {
    name: "account-documents",
    path: "/account/documents",
    meta: documentsHuseUiafUOx9A1UrORwM_YkhikhWXX6cyFScx7Ycx1wMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/documents.vue")
  },
  {
    name: "category-slug",
    path: "/category/:slug(.*)*",
    meta: _91_46_46_46slug_93MncC08W7EZDdTNm53Jc96KVso1csgnIJx_45EP45taNHsMeta || {},
    alias: ["/c/:slug(.*)*"],
    component: () => import("/opt/buildhome/repo/pages/category/[...slug].vue")
  },
  {
    name: "account-orders-id",
    path: "/account/orders/:id()",
    component: () => import("/opt/buildhome/repo/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders",
    path: "/account/orders",
    meta: indexZbLlXqe_45wEtVUmy6VGSnLOfOi62mTrWOclwgnuDcPmoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/orders/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notificationsX94bmcoQuxHnT_yLyOU1rvk_45ujcvbrxitGpviN3o0BAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/notifications.vue")
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/knives-and-cutlery/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/new-handguns/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/used-handguns/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/new-rifles/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/used-rifles/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/new-shotguns/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/used-shotguns/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/new-receivers/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/class-iii-nfa/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/airguns/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/firearm-components/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/camping/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/category/boating/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  }
]