import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/opt/buildhome/repo/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import strapi_sKhLINQNdwt_mhHLPJB0LMn9ASgy8vLNsKSmAAgEZsg from "/opt/buildhome/repo/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.js";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/opt/buildhome/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc from "/opt/buildhome/repo/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_L8UGadL0X0unLMI5Gais2tVmEbRW3rkathO0Vxyij4E from "/opt/buildhome/repo/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import _00_ecommerce_9vsVYZW92YGR9BMCEhPQaYyJDOSRmsmDMSOa5F0oD8s from "/opt/buildhome/repo/plugins/00.ecommerce.ts";
import _00_typesense_Yg1QdcB5dRlhkDneHMaKj_ql_B7FkLvuXGBS5ixsB6o from "/opt/buildhome/repo/plugins/00.typesense.ts";
import accessibly_client_EoEy3Si9XQiBcaTmconfQrccjZ_uizCk656jf3xVbqE from "/opt/buildhome/repo/plugins/accessibly.client.ts";
import analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U from "/opt/buildhome/repo/plugins/analytics.client.ts";
import avantlink_zw3v6vj4ojwzIKZrmcc9jTuUyLdTkXwG_KjaHul28PI from "/opt/buildhome/repo/plugins/avantlink.ts";
import bus_AhchMQfPWMXQP5p4bH3V2zYjymi77HN_Ap7_q3YH_ms from "/opt/buildhome/repo/plugins/bus.ts";
import clarity_33sIVOgru60oR2kUw4R4PfTV1ez1PdYZU5H8ZIwhi08 from "/opt/buildhome/repo/plugins/clarity.ts";
import credova_Ig61RpqPJsQKWIIMPybwifqzy0ER8bbrLR9u6DCPu08 from "/opt/buildhome/repo/plugins/credova.ts";
import klaviyo_DLnyOHkGu_on6O93Gf6sWqmoPGKRhXsYGXXw7AukfKo from "/opt/buildhome/repo/plugins/klaviyo.ts";
import kount_2TXavGllSxlnoB_Hxo4m9scHGyEKGxP0DQxAmTTMZZ4 from "/opt/buildhome/repo/plugins/kount.ts";
import marketing_6wJnMOvABfVQ_Aj115E_bwDaxKb_A_7fBnjWM0QFM_w from "/opt/buildhome/repo/plugins/marketing.ts";
import meta_Rdifr0EXNKb207CAT4jpP7nZQbC2iCU1whTviATYeM4 from "/opt/buildhome/repo/plugins/meta.ts";
import tawk_knOEvVFOZcsbit_6hMyRUFXwdyOttOnMccZwgNq5AUY from "/opt/buildhome/repo/plugins/tawk.ts";
import _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w,
  inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o,
  titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg,
  strapi_sKhLINQNdwt_mhHLPJB0LMn9ASgy8vLNsKSmAAgEZsg,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc,
  plugin_L8UGadL0X0unLMI5Gais2tVmEbRW3rkathO0Vxyij4E,
  _00_ecommerce_9vsVYZW92YGR9BMCEhPQaYyJDOSRmsmDMSOa5F0oD8s,
  _00_typesense_Yg1QdcB5dRlhkDneHMaKj_ql_B7FkLvuXGBS5ixsB6o,
  accessibly_client_EoEy3Si9XQiBcaTmconfQrccjZ_uizCk656jf3xVbqE,
  analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U,
  avantlink_zw3v6vj4ojwzIKZrmcc9jTuUyLdTkXwG_KjaHul28PI,
  bus_AhchMQfPWMXQP5p4bH3V2zYjymi77HN_Ap7_q3YH_ms,
  clarity_33sIVOgru60oR2kUw4R4PfTV1ez1PdYZU5H8ZIwhi08,
  credova_Ig61RpqPJsQKWIIMPybwifqzy0ER8bbrLR9u6DCPu08,
  klaviyo_DLnyOHkGu_on6O93Gf6sWqmoPGKRhXsYGXXw7AukfKo,
  kount_2TXavGllSxlnoB_Hxo4m9scHGyEKGxP0DQxAmTTMZZ4,
  marketing_6wJnMOvABfVQ_Aj115E_bwDaxKb_A_7fBnjWM0QFM_w,
  meta_Rdifr0EXNKb207CAT4jpP7nZQbC2iCU1whTviATYeM4,
  tawk_knOEvVFOZcsbit_6hMyRUFXwdyOttOnMccZwgNq5AUY,
  _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM,
  defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk
]