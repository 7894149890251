import type { BaseAddress } from '~/types/ecommerce';

interface FormatMoneyOptions {
  separator?: string; // Thousands separator (e.g., ",")
  decimalPoint?: string; // Decimal separator (e.g., ".")
  decimals?: number; // Number of decimal places
  symbol?: string; // Currency symbol (e.g., "$")
  append?: boolean; // Whether to place symbol after the number
  leadZeros?: boolean; // Whether to keep leading zeros
}

const defaults: FormatMoneyOptions = {
  separator: ',',
  decimalPoint: '.',
  decimals: 2,
  symbol: '$',
  append: false,
  leadZeros: true,
};

const formatCurrency = (
  value: number,
  options: FormatMoneyOptions = defaults,
): string | undefined => {
  if (!Number.isFinite(value)) {
    return undefined; // Handle NaN & non-number values
  }

  const { separator, decimalPoint, decimals, symbol, append, leadZeros } = {
    ...defaults,
    ...options,
  } as Required<FormatMoneyOptions>;

  const absValue = Math.abs(value);
  let formatted = absValue.toFixed(decimals);

  if (!leadZeros) {
    formatted = String(parseFloat(formatted)); // Remove unnecessary leading zeros
  }

  const resultArr = formatted.split('.');
  const left: string = resultArr[0] ?? '0'; // Ensure left is always defined
  const right: string = resultArr.length > 1 ? decimalPoint + resultArr[1] : '';

  // **Manual Thousands Separator Formatting** (for Node.js)
  const formattedLeft = left.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  const result = `${value < 0 ? '-' : ''}${append ? formattedLeft + right + symbol : symbol + formattedLeft + right}`;

  return result;
};

const formatPercent = (value: number): string => {
  return `${value.toFixed()}%`;
};

const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${(count !== 1 && count !== 0) ? suffix : ''}`;

const formatRating = (value: number) => {
  return value.toFixed(2).replace(/0{1}$/, '');
};

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber
    .replace(/\D/g, '')
    .replace(
      /(\d*)(\d{3})(\d{3})(\d{4})$/,
      (s, a, b, c, d) => `+${a} (${b}) ${c}-${d}`,
    )
    .replace(/\+(1\b|\s)\s*/, '');
};

const formatAddress = (address: MaybeRefOrGetter<BaseAddress>) =>
  join(
    concat(
      ', ',
      toValue(address).street,
      toValue(address).street2,
      toValue(address).city,
      toValue(address).state,
    ),
    toValue(address).postcode,
  );

const concat = (separator: string, ...parts: (string | null | undefined)[]) => {
  return parts
    .filter(p => !!p?.length)
    .join(separator)
    .trim();
};

const join = (...parts: (string | null | undefined)[]) => {
  return concat(' ', ...parts);
};

const maskify = (value: string, last = 4, char = 'X') =>
  value.slice(0, last * -1).replace(/./g, char) + value.slice(last * -1);

export const paragraphize = (text?: string | null) => {
  if (!text?.trim()?.length) {
    return '';
  }

  return text
    .trim()
    .replace(/\r\n/g, '\n') // Normalize CRLF to LF
    .split(/\n\s*\n+/) // Split into paragraphs, collapsing excessive blank lines
    .filter(Boolean) // Remove empty elements to prevent empty <p> tags
    .map(p => `<p>${p.replace(/\n/g, '<br>')}</p>`) // Convert single line breaks to <br>
    .join('');
};

export const useFormatting = () => {
  return {
    currency: formatCurrency,
    percent: formatPercent,
    rating: formatRating,
    phone: formatPhoneNumber,
    address: formatAddress,
    maskify,
    pluralize,
    paragraphize,
    concat,
    join,
  };
};
