import { whenever } from '@vueuse/core';

type ClarityFunctions = ((fn: 'start', options: { content: boolean; cookies: string[]; dob: number; expire: number; projectId: string; upload: string }) => void)
  & ((fn: 'identify', id: string, session?: string, page?: string, userHint?: string) => Promise<{
    id: string;
    session: string;
    page: string;
    userHint: string;
  }>)
  & ((fn: 'consent') => void)
  & ((fn: 'set', key: string, ...value: (string | string[])[]) => void)
  & ((fn: 'event', value: string) => void)
  & ((fn: 'upgrade', upgradeReason: string) => void)
  & ((fn: string, ...args: unknown[]) => void);

declare global {
  interface Window {
    clarity: ClarityFunctions & {
      q: unknown[];
      v: string;
    };
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:clarity',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const userStore = useUserStore();
    const config = useRuntimeConfig().public.clarity;

    const { $bus } = useNuxtApp();

    const enabled = config.enabled && !!config.id;

    const { status, onError, load, proxy } = useScript({
      src: `https://www.clarity.ms/tag/${config.id}`,
      referrerpolicy: false,
      crossorigin: false,
    },
    {
      trigger: 'manual',
      mode: 'client',
      beforeInit: () => {
        if (import.meta.server) {
          return;
        }

        window.clarity = window.clarity || function (...params: unknown[]) {
          (window.clarity.q = window.clarity.q || []).push(params);
        } as ClarityFunctions;
      },
      use: () => ({
        clarity: window.clarity,
        consent: () => window.clarity('consent'),
        upgrade: (reason: string) => window.clarity('upgrade', reason),
        track: (event: string) => window.clarity('event', event),
        tag: (key: string, ...value: (string | string[])[]) => window.clarity('set', key, value),
        identify: (
          customId: string,
          customSessionId?: string,
          customPageId?: string,
          friendlyName?: string) => window.clarity('identify', customId, customSessionId, customPageId, friendlyName),
      }),
    });

    /**
     * Since this script is not critical to the site, we'll just catch the exception and do nothing.
     * The API is stubbed, so it will be safe to use but will do nothing without the script.
     */
    onError(noop);

    const ready = computed(() => status.value == 'loaded');

    whenever(
      () => !!userStore.data && ready.value,
      () => proxy.identify(userStore.data!.id, undefined, undefined, userStore.data!.id),
    );

    $bus.once('app:interaction', () => !enabled || load());

    $bus.on('checkout:start', () => {
      proxy.upgrade('Started checkout');
      proxy.track('Start checkout');
    });

    $bus.on('checkout:cancel', () => proxy.track('Cancel checkout'));
    $bus.on('checkout:complete', () => proxy.track('Complete checkout'));

    $bus.on('credova:checkout:start', () => proxy.track('Start Credova checkout'));
    $bus.on('credova:checkout:cancel', () => proxy.track('Cancel Credova checkout'));
    $bus.on('credova:checkout:complete', () => proxy.track('Complete Credova checkout'));

    return {
      provide: {
        clarity: {
          enabled,
          ready,
          upgrade: proxy.upgrade,
          consent: proxy.consent,
          identify: proxy.identify,
          tag: proxy.tag,
          track: proxy.track,
        },
      },
    };
  },
});
