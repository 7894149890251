export function usePromiseLock<T>(fn: () => Promise<T>) {
  let promise: Promise<T> | null = null;

  const execute = async (): Promise<T> => {
    if (promise) {
      return promise; // Return existing promise if already running
    }

    promise = fn().finally(() => {
      promise = null; // Reset when execution finishes
    });

    return promise;
  };

  return execute;
}
